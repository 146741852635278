<template>
    <div>404 路径错误</div>
</template>

<script>
export default {
    components: {},
};
</script>

<style scoped></style>
